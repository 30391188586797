export default {
  'App Store Button': 'Бутон за App Store',
  'App Store QR Button': 'QR Button QR Button',
  'Download the app': 'Изтеглете приложението',
  featuresQuality:
    'За разлика от други приложения, всички наши снимки преминават през <1>дълбок контрол на качеството</1>. По-малко от 1% от снимките са одобрени',
  featuresQuantity:
    'NoFilter съдържа <1>13000 петна</1> по света, а около <1>100 нови снимки се добавят всяка седмица</1>',
  featuresPrice: 'О... да... И това е <1>безплатно</1> и е достъпно за <1>Android и iOS</1>',
  'Geolocation is not supported by this browser': 'Геолокацията не се поддържа от този браузър',
  'Getting photo locations': 'Получаване на снимки за снимки',
  'Getting your location...': 'Получаване на местоположението ви...',
  'Google Play Button': 'Google Play Бутон',
  'Google Play QR Button': 'QR бутон на Google Play',
  'Join NoFilter today': 'Присъединете се към NoFilter днес',
  'Our goal': 'Нашата цел',
  ourGoal:
    'Помогнете на <1>фотографи и пътешественици</1>, които искат да направят <1>невероятни снимки</1>, като предоставят приложение, където могат да виждат готини снимки на снимки и дестинации за пътуване по целия свят.',
  'Price and Support': 'Цена и поддръжка',
  Quality: 'качество',
  Quantity: 'количество',
  'Show me places nearby!': 'Покажи ми места наблизо!',
  'Some examples': 'Няколко примера',
  titleGeneral:
    'Ние помагаме на <1>пътешественици и фотографи</1> да открият <1>най-добрите места за снимки</1> по целия свят',
  "Want to see more? Download the app. It's free!":
    'Искам да видя още? Изтеглете приложението. Безплатно е!',
  noLocationPermission:
    'Браузърът ви не иска да ни даде позицията си :( опитайте от вашия компютър или просто изтеглете NoFilter в телефона си и го опитайте истински!',
  'Some cities': 'някои градове',
  'Some photos': 'някои снимки',
  Loading: 'Зареждане',
  'You just got your free membership!': 'Току-що получихте своето безплатно членство!',
  'Show in Map': 'Показване в картата',
};
