import './utils/i18n';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import duix from 'duix';
import Home from 'pages/Home';
// import PrivacyPolicy from 'pages/PrivacyPolicy';
// import TermsOfUse from 'pages/TermsOfUse';
// import Contact from 'pages/Contact';
// import Developers from 'pages/Developers';
import PublicProfile from 'pages/PublicProfile';
import Spot from 'pages/Spot';
// import Area from 'pages/Area';
// import BrandToolkit from 'pages/BrandToolkit';
// import Unsubscribe from 'pages/Unsubscribe';
// import WeMissYou from 'pages/WeMissYou';
import Header from 'components/Header';
import Footer from 'components/Footer';
import i18n from 'i18next';
import './styles/reseter.css';
import './styles/app.scss';

// const Home = lazy(() => import('./pages/Home'));
const TermsOfUse = lazy(() => import('./pages/TermsOfUse'));
const Contact = lazy(() => import('./pages/Contact'));
const Developers = lazy(() => import('./pages/Developers'));
const Area = lazy(() => import('./pages/Area'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const WeMissYou = lazy(() => import('./pages/WeMissYou'));
const Unsubscribe = lazy(() => import('./pages/Unsubscribe'));
const BrandToolkit = lazy(() => import('./pages/BrandToolkit'));

// Init
duix.set('user', null);

try {
  duix.set('user', JSON.parse(window.localStorage.getItem('user')));
} catch (error) {}

duix.subscribe('user', user => {
  try {
    window.localStorage.setItem('user', JSON.stringify(user));
  } catch (error) {}
});

const RedirectTo = ({ url }) => {
  window.location.href = url;
  return <a href={url}>Redirecting...</a>;
};

function App() {
  return (
    <BrowserRouter>
      <Header />

      <Suspense
        fallback={
          <div style={{ paddingBottom: '400px', paddingTop: '40px', textAlign: 'center' }}>
            {i18n.t('Loading')}...
          </div>
        }
      >
        <Routes>
          <Route path="/spot/:slug" element={<Spot />} />
          <Route path="/best-photo-spots-in/:slug" element={<Area />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/brand-toolkit" element={<BrandToolkit />} />
          <Route
            path="/ios"
            element={
              <RedirectTo url="https://apps.apple.com/app/nofilter-photo-spots/id1445583976" />
            }
          />
          <Route
            path="/android"
            element={
              <RedirectTo url="https://play.google.com/store/apps/details?id=app.no_filter.nofilter" />
            }
          />
          <Route path="/unsubscribe/:userId/:hash" element={<Unsubscribe />} />
          <Route path="/we-miss-you" element={<WeMissYou />} />
          {/* Keep it here, because it's kind of "*" */}
          <Route path="/:username" element={<PublicProfile />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Suspense>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
