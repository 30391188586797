import React from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';

const MAP_CONTAINER_STYLES = {
  width: '100%',
  height: '100%',
};

const Map = ({ spots, onClick = () => {} }) => {
  const allLats = spots.map(x => x.lat).slice(0, 15);
  const allLngs = spots.map(x => x.lng).slice(0, 15);
  const center = {
    lat: (Math.max(...allLats) + Math.min(...allLats)) / 2,
    lng: (Math.max(...allLngs) + Math.min(...allLngs)) / 2,
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCuqYq7DeyQP2NfpfI-b-XgqyBtTN2sZl0',
  });

  if (!isLoaded || !center) {
    return null;
  }

  return (
    <GoogleMap
      mapContainerStyle={MAP_CONTAINER_STYLES}
      // TODO: This default zoom & center should be dynamic
      center={center}
      zoom={spots.length > 1 ? 11 : 15}
    >
      {spots.map(spot => (
        <Marker
          key={spot.id}
          onClick={() => onClick(spot)}
          position={{ lat: spot.lat, lng: spot.lng }}
        />
      ))}
    </GoogleMap>
  );
};

export default Map;
