import React from 'react';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="Loading">
      <div>...{t('Loading')}...</div>
      <div className="Loading__emoji">
        <span role="img" aria-label="Selfie">
          🤳
        </span>
      </div>
    </div>
  );
};

export default Loading;
