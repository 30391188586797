export const changeCanonical = (url, extras = {}) => {
  const isSpot = url.startsWith('/spot/') && extras.image;

  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Place',
    url: `https://getnofilter.com${url}`,
  };

  if (!extras.title) {
    extras.title = 'NoFilter - The best photo spots nearby and all around the world';
  } else {
    structuredData.name = extras.title;
  }

  if (!extras.description) {
    extras.description =
      'NoFilter helps you discover cool photo spots nearby and all around the world. Free for Android and iOS';
  }

  if (!extras.image) {
    extras.image =
      'https://images.unsplash.com/photo-1492691527719-9d1e07e534b4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1280&q=80';
  } else {
    structuredData.image = extras.image;
    // TODO: Add https://schema.org/photo
    // structuredData.photo = extras.image;
  }

  if (extras.coors) {
    structuredData.geo = {
      '@type': 'GeoCoordinates',
      latitude: extras.coors.lat,
      longitude: extras.coors.lng,
    };
  }

  document
    .querySelector('head link[rel="canonical"]')
    .setAttribute('href', `https://getnofilter.com${url}`);
  document
    .querySelector('head meta[property="og:url"]')
    .setAttribute('content', `https://getnofilter.com${url}`);

  if (extras.title) {
    document.title = extras.title;
    document.querySelector('head meta[property="og:title"]').setAttribute('content', extras.title);
  }

  if (extras.description) {
    document
      .querySelector('head meta[name="description"]')
      .setAttribute('content', extras.description);
    document
      .querySelector('head meta[property="og:description"]')
      .setAttribute('content', extras.description);
  }

  if (extras.image) {
    document.querySelector('head meta[property="og:image"]').setAttribute('content', extras.image);
  }

  try {
    const structuredDataElement = document.getElementById('structuredData');

    if (structuredDataElement) {
      if (isSpot) {
        structuredDataElement.textContent = JSON.stringify(structuredData);
      } else {
        structuredDataElement.textContent = '';
      }
    }
  } catch (error) {
    //
  }
};
