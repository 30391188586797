import config from './../config';
import fetchUtils from './../utils/fetch';

const getBySlug = slug => {
  return fetch(`${config.api.baseUrl}/landing/v1/spots/get-by-slug/${slug}`, {
    method: 'GET',
    headers: {
      ...fetchUtils.getHeaders(),
    },
  }).then(fetchUtils.parseResponse);
};

const getByIds = ids => {
  return fetch(`${config.api.baseUrl}/landing/v1/spots/get-by-ids`, {
    method: 'POST',
    headers: {
      ...fetchUtils.getHeaders(),
    },
    body: JSON.stringify({ ids }),
  }).then(fetchUtils.parseResponse);
};

const getAdditionalData = spotId => {
  return fetch(`${config.api.baseUrl}/landing/v2/spots/get-additional-data?spotId=${spotId}`, {
    method: 'GET',
    headers: {
      ...fetchUtils.getHeaders(),
    },
  }).then(fetchUtils.parseResponse);
};

const getTopForLanding = () => {
  return fetch(`/top-spots-v2.json`, {
    method: 'GET',
  });
};

export default {
  getBySlug,
  getByIds,
  getAdditionalData,
  getTopForLanding,
};
