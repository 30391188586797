export const urlParserV16 = (url, size = 1080) => {
  return url
    .replace('{0}', 'https://storage.googleapis.com/mari-a5cc7.appspot.com/photos/regular/')
    .replace('{1}', 'https://images.unsplash.com/photo-')
    .replace(
      '{2}',
      `?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=${size}&fit=max&ixid=eyJhcHBfaWQiOjIzOTI1fQ`
    )
    .replace(
      '{3}',
      `?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=${size}&fit=max&ixid=eyJhcHBfaWQiOjIzOTI1fQ&s=`
    )
    .replace('{4}', '?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=')
    .replace('{5}', `&ixlib=rb-1.2.1&q=80&w=${size}`)
    .replace('{6}', 'MXwyMzkyNXwwfDF8c2VhcmNofD')
    .replace('{7}', 'MnwyMzkyNXwwfDF8c2VhcmNofD');
};
