import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import Download from '../../components/Download';
import Map from '../../components/Map';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import { changeCanonical } from 'utils/seo';
import './styles.scss';

const Spot = () => {
  const isMobile = window.innerWidth <= 700;
  const params = useParams();
  const slug = params.slug;

  changeCanonical(`/spot/${slug}`);

  const { t } = useTranslation();

  const [spot, setSpot] = useState(null);
  const [tips, setTips] = useState([]);
  const [index, setIndex] = useState(0);
  const [isMapShown, setIsMapShown] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      if (!slug) {
        return;
      }

      const r = await fetch(
        `https://storage.googleapis.com/mari-a5cc7.appspot.com/spots/info/v1/${slug}.json`
      );
      const spot = await r.json();
      setSpot(spot);

      if (Array.isArray(spot.tips) && spot.tips.length > 0) {
        setTips(spot.tips);
      }

      const from = spot.from ? `From ${spot.from}, ${spot.country}` : spot.country;
      changeCanonical(`/spot/${slug}`, {
        title: `${spot.name} - ${from}`,
        description: spot.description
          ? `All the information about: ${spot.name} - ${from}: ${spot.description}`
          : `All the information about: ${spot.name} - ${from}`,
      });

      // const { spot } = await actions.spots.getBySlug(slug);
      // setSpot(spot);

      // const from = spot.from ? `From ${spot.from}, ${spot.country}` : spot.country;
      // changeCanonical(`/spot/${slug}`, {
      //   title: `${spot.name} - ${from}`,
      //   description: spot.description
      //     ? `All the information about: ${spot.name} - ${from}: ${spot.description}`
      //     : `All the information about: ${spot.name} - ${from}`,
      // });

      // const { tips, photosHydrated, userPhotosHydrated } = await actions.spots.getAdditionalData(
      //   spot.id
      // );
      // setTips(tips);

      // const newSpot = { ...spot };

      // newSpot.photosHydrated = photosHydrated;
      // newSpot.userPhotosHydrated = userPhotosHydrated;
      // setSpot(newSpot);
    })();
  }, [slug]);

  if (!spot) {
    return (
      <div className="content-wrapper" style={{ paddingBottom: '100vh' }}>
        <Loading />
      </div>
    );
  }

  // { url, username, isUnsplashUser }
  const images = [
    ...spot.photosHydrated.map(photo => ({
      url: photo.urls.regular,
      username: photo.user.username,
      aspectRatio: photo.width / photo.height,
      isUnsplashUser: true,
    })),
    ...spot.userPhotosHydrated.map(photo => ({
      url: photo.url ? photo.url : photo.urls.regular,
      username: photo.user.username,
      aspectRatio: photo.width / photo.height,
      isNoFilterUser: true,
    })),
  ];

  const image = images[index];

  try {
    const nextImage = images[index + 1];

    if (nextImage) {
      const img = new Image();
      img.src = nextImage.url;
    }
  } catch (error) {
    //
  }

  const from = spot.from ? `From ${spot.from}, ${spot.country}` : spot.country;

  changeCanonical(`/spot/${slug}`, {
    title: `${spot.name} - ${from}`,
    description: `All the information about this cool viewpoint: ${spot.name} - ${from}`,
    image: image.url,
    coors: {
      lat: spot.lat,
      lng: spot.lng,
    },
  });

  return (
    <div className="Spot">
      <div className="content-wrapper">
        <article>
          <div className="Spot__we">
            <Trans i18nKey="titleGeneral">
              We help <u>travelers and photographers</u> to discover <u>the best photo locations</u>{' '}
              all around the world
            </Trans>
          </div>

          <h1>
            {spot.name} - {from}
          </h1>

          <div className="Spot__wrapper">
            <div className="Spot__photo" data-n={images.length}>
              <figure>
                <img
                  className="img"
                  src={image.url}
                  alt={`${spot.name} ${from}`}
                  style={{
                    aspectRatio: `${image.aspectRatio} / 1`,
                  }}
                />
                <figcaption>{`${spot.name} ${from}`}</figcaption>

                <div className="Spot__by">
                  Photo by{' '}
                  {image.isNoFilterUser && <Link to={`/${image.username}`}>{image.username}</Link>}
                  {image.isUnsplashUser && <span>@{image.username} - Unsplash</span>}
                </div>
              </figure>

              {index > 0 && (
                <div className="Spot__arrow Spot__arrow--left" onClick={() => setIndex(index - 1)}>
                  &#8592;
                </div>
              )}

              {index < images.length - 1 && (
                <div className="Spot__arrow Spot__arrow--right" onClick={() => setIndex(index + 1)}>
                  &#8594;
                </div>
              )}
            </div>

            {isMobile && !isMapShown && (
              <div className="Spot__show-map">
                <Button onClick={() => setIsMapShown(true)}>{t('Show in Map')}</Button>
              </div>
            )}

            {(!isMobile || isMapShown) && (
              <div className="Spot__map">
                {' '}
                <Map spots={[spot]} />
              </div>
            )}
          </div>

          <div
            className="Spot__description"
            dangerouslySetInnerHTML={{
              __html:
                spot.description && spot.description.en
                  ? spot.description.en.replace('\n', '<br />')
                  : 'No description available yet 🤷',
            }}
          />

          {tips.length > 0 && (
            <>
              <h2>Tips and Comments</h2>
              <div className="Spot__tips">
                {tips.map((tip, i) => (
                  <div key={tip.id} className="Spot__tip">
                    {i + 1}. {tip.text}
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="Spot__download">
            <div>{t("Want to see more? Download the app. It's free!")}</div>

            <Download />
          </div>
        </article>
      </div>
    </div>
  );
};

export default Spot;
