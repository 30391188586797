export default {
  'App Store Button': "Botó d'App Store",
  'App Store QR Button': "Botó QR d'App Store",
  'Download the app': "Descarrega l'aplicació",
  featuresQuality:
    "A diferència d'altres aplicacions, totes les nostres fotos passen per un <1>control de qualitat profund</1>. Menys de l’1% de les fotos estan aprovades",
  featuresQuantity:
    'NoFilter conté <1>13000 llocs</1> arreu del món, i al voltant de <1>100 fotos noves s’afegeixen cada setmana</1>',
  featuresPrice: 'Oh... Sí... I és <1>gratuït</1>, i està disponible per a <1>Android i iOS</1>',
  'Geolocation is not supported by this browser':
    'La geolocalització no és compatible amb aquest navegador',
  'Getting photo locations': 'Aconseguint ubicacions fotogràfiques',
  'Getting your location...': 'Com obtenir la vostra ubicació...',
  'Google Play Button': 'Botó de Google Play',
  'Google Play QR Button': 'Botó QR de Google Play',
  'Join NoFilter today': 'Uniu-vos a NoFilter avui',
  'Our goal': 'El nostre objectiu',
  ourGoal:
    'Ajudeu als <1>fotògrafs i viatgers</1> que vulguin fer <1>imatges sorprenents</1>, proporcionant una aplicació on puguin veure ubicacions fotogràfiques fantàstiques i destinacions de viatges a tot el món.',
  'Price and Support': 'Preu i assistència',
  Quality: 'Qualitat',
  Quantity: 'Quantitat',
  'Show me places nearby!': "Mostra'm llocs propers!",
  'Some examples': 'Alguns exemples',
  titleGeneral:
    'Ajudem <1>viatgers i fotògrafs</1> a descobrir <1>les millors ubicacions de fotos</1> arreu del món',
  "Want to see more? Download the app. It's free!":
    "Voleu veure més? Baixeu l'aplicació. És gratis!",
  noLocationPermission:
    'El vostre navegador no ens vol donar la vostra posició :( proveu-lo des del vostre PC, o simplement descarregueu NoFilter al vostre telèfon i proveu-lo de veritat.',
  'Some cities': 'Algunes ciutats',
  'Some photos': 'Algunes fotos',
  Loading: 'Carregant',
  'You just got your free membership!': 'Acabes de rebre la teva subscripció gratuïta!',
  'Show in Map': 'Mostra al mapa',
};
