export default {
  'App Store Button': 'Botão da App Store',
  'App Store QR Button': 'Botão QR da App Store',
  'Download the app': 'Baixe o aplicativo',
  featuresQuality:
    'Ao contrário de outros aplicativos, todas as nossas fotos passam por <1>um profundo controle de qualidade</1>. Menos de 1% das fotos são aprovadas',
  featuresQuantity:
    'O NoFilter contém <1>13000 spots</1> em todo o mundo e cerca de <1>100 novas fotos são adicionadas a cada semana</1>',
  featuresPrice: 'Ah... Sim... E é <1>gratuito</1> e está disponível para <1>Android e iOS</1>',
  'Geolocation is not supported by this browser':
    'A localização geográfica não é suportada por este navegador',
  'Getting photo locations': 'Obtendo locais de fotos',
  'Getting your location...': 'Obtendo sua localização...',
  'Google Play Button': 'Botão do Google Play',
  'Google Play QR Button': 'Botão QR do Google Play',
  'Join NoFilter today': 'Inscreva-se no NoFilter hoje',
  'Our goal': 'Nosso objetivo',
  ourGoal:
    'Ajude <1>fotógrafos e viajantes</1> que desejam tirar <1>fotos incríveis</1>, fornecendo um aplicativo onde eles podem ver locais legais de fotos e destinos de viagem em todo o mundo.',
  'Price and Support': 'Preço e Suporte',
  Quality: 'Qualidade',
  Quantity: 'Quantidade',
  'Show me places nearby!': 'Mostre-me lugares próximos!',
  'Some examples': 'Alguns exemplos',
  titleGeneral:
    'Ajudamos <1>viajantes e fotógrafos</1> a descobrir <1>os melhores locais para fotos</1> em todo o mundo',
  "Want to see more? Download the app. It's free!": 'Quer ver mais? Baixe o aplicativo. É grátis!',
  noLocationPermission:
    'Seu navegador não deseja nos dar sua posição :( tente no seu PC ou faça o download do NoFilter no seu telefone e tente de verdade!',
  'Some cities': 'Algumas cidades',
  'Some photos': 'Algumas fotos',
  Loading: 'Carregando',
  'You just got your free membership!': 'Você acabou de obter sua assinatura gratuita!',
  'Show in Map': 'Mostrar no mapa',
};
