import api from '../api';

const getBySlug = slug => {
  return api.spots.getBySlug(slug).then(r => r.data);
};

const getByIds = ids => {
  return api.spots.getByIds(ids).then(r => r.data);
};

const getAdditionalData = spotId => {
  return api.spots.getAdditionalData(spotId).then(r => r.data);
};

const getTopForLanding = () => {
  return Promise.resolve({
    spots: [
      {
        s: 'charco-de-las-fuentes-from-los-charcos-de-quesa-in-spain',
        u: '{1}1632077067721-ad3abd7b6433{4}{7}I5MjV8fHNlcnJhcyUyMHZlcmRlcyUyMGRvJTIwc3VsJTIwZGUlMjBtaW5hc3xlbnwwfHx8fDE2NTIwMzQwMDc{5}',
      },
      {
        s: 'bocairent-from-mirador-del-cementeri-in-spain',
        u: '{1}1523381286178-92cd8ac11e69{3}2cd575db2cef83beee9df8bee36e0269',
      },
      { s: 'muralla-roja-in-spain', u: '{1}1578266848416-c291bc0b2940{2}' },
      {
        s: 'les-cases-de-colors-de-villajoyosa-in-spain',
        u: '{1}1639074518512-38c348792011{4}{7}M2Mjd8fG5lbHNvbiUyMGdob3N0JTIwdG93bnxlbnwwfHx8fDE2NTUxNjI3NDk{5}',
      },
      {
        s: 'cueva-negra-in-spain',
        u: '{1}1605487835396-b000ce3278c7{4}{7}IwODl8fHNwYWlufGVufDB8fHx8MTYyNTU1MDc2NA{5}',
      },
      {
        s: 'castillo-musulman-y-catedral-de-albarracin-from-plaza-mayor-albarracin-in-spain',
        u: '{1}1591174181830-0329dac0f0d6{2}',
      },
      {
        s: 'murallas-de-albarracin-from-mirador-in-spain',
        u: '{1}1627558751472-6c482d74072d{4}{7}c1Njd8fGNhc3RsZXxlbnwwfHx8fDE2NTY5NTQzMDc{5}',
      },
      {
        s: 'cathedral-basilica-of-saint-louis-from-outside-in-united-states',
        u: '{1}1590084625830-22ad1c1304c9{2}',
      },
      {
        s: 'saint-louis-art-museum-from-picnic-island-in-united-states',
        u: '{1}1584587278548-5c862fe26ef2{2}',
      },
      {
        s: 'qatar-flagpole-from-national-museum-of-qatar-in-qatar',
        u: '{1}1646207795621-733fe1646f9b{4}{7}E0OHx8cWF0YXJ8ZW58MHx8fHwxNjU2OTU0NzAx{5}',
      },
      {
        s: 'pigeon-nest-from-katara-cultural-village-in-qatar',
        u: '{1}1647252285041-9b2886aff1c6{4}{7}IwNHx8cWF0YXJ8ZW58MHx8fHwxNjU2OTU0NzAy{5}',
      },
      {
        s: 'qatar-faculty-of-islamic-studies-in-qatar',
        u: '{1}1647755072672-7187fdc11cfb{4}{7}I1OHx8cWF0YXJ8ZW58MHx8fHwxNjU2OTU0NzAy{5}',
      },
      {
        s: 'point-vicente-lighthouse-from-point-vicente-interpretive-center-in-united-states',
        u: '{1}1597740992388-c662b3e5850b{2}',
      },
      { s: 'the-palms-view-from-drone-in-united-states', u: '{1}1570658379397-89d15db1b0de{2}' },
      {
        s: 'secret-swing-angels-point-from-elysian-park-in-united-states',
        u: '{1}1500230622307-3ee8dea8096b{3}eba0dbfc78da008f4850473400cd7bc3',
      },
      {
        s: 'platform-ellen-from-life-guard-tower-3-in-united-states',
        u: '{1}1538439543-6a2487829c85{2}',
      },
      { s: 'will-rogers-memorial-park-in-united-states', u: '{1}1593973628030-cbb3a38c50e9{2}' },
      { s: 'beverly-hills-city-hall-in-united-states', u: '{1}1583727149612-44985878e588{2}' },
      {
        s: 'los-angeles-from-mt-lee-in-united-states',
        u: '{1}1634141737319-92cc2941b735{4}{7}IyMzN8fGxvcyUyMGFuZ2VsZXN8ZW58MHx8fHwxNjU2Njg1MTQ1{5}',
      },
      {
        s: 'santa-monica-mountains-national-recreation-area-from-runyon-canyon-flag-in-united-states',
        u: '{1}1589185439827-206aa755f1d1{2}',
      },
      {
        s: 'runyon-canyon-park-from-w-ridge-hiking-trail-in-united-states',
        u: '{1}1639336714708-83e1963a701c{4}{7}IzNTh8fGxvcyUyMGFuZ2VsZXN8ZW58MHx8fHwxNjU2Njg1MTQ1{5}',
      },
      { s: 'cape-bojeador-lighthouse-in-philippines', u: '{1}1575728463625-77958642ecfe{2}' },
      {
        s: 'kawasan-falls-from-approximate-area-in-philippines',
        u: '{1}1540650444158-8b98c95ea98d{2}',
      },
      { s: 'the-ruins-in-philippines', u: '{1}1578363299198-c0cbb7e9739d{2}' },
      { s: 'malatan-og-falls-viewing-deck-in-philippines', u: '{1}1594836381416-5e5b1deca70c{2}' },
      {
        s: 'saint-olafs-church-in-tyrvaa-from-the-medieval-church-of-tyrvaa-in-finland',
        u: '{1}1597661580390-276e36a8b761{4}{6}Q3MDR8fGNodXJjaHxlbnwwfHx8{5}',
      },
      {
        s: 'aigle-castle-from-av-veillon-in-switzerland',
        u: '{1}1612660586635-280c73120453{4}{7}E1MjV8fGNhc3RsZXxlbnwwfHx8fDE2NTY5NTQyNTg{5}',
      },
      {
        s: 'chateau-de-chillon-from-le-cabanon-de-mam‘s-in-switzerland',
        u: '{1}1611429894269-02252d9bab76{4}{7}E0MzV8fGNhc3RsZXxlbnwwfHx8fDE2NTY5NTQyNTc{5}',
      },
      { s: 'huis-ten-bosch-in-japan', u: '{1}1513242285-8905cc370848{2}' },
      {
        s: 'topaz-princess-mermaid-from-haeundae-beach-in-south-korea',
        u: '{1}1597231263705-4760c4d98b9f{4}{6}UyMHx8a29yZWF8ZW58MHx8fA{5}',
      },
      {
        s: 'sindang-dong-from-hengso-museum-of-keimyung-university-in-south-korea',
        u: '{1}1585554555426-3b8b62fcfb11{4}{6}IwMTd8fGtvcmVhfGVufDB8fHw{5}',
      },
      {
        s: 'palace-on-the-isle-from-north-west-side-in-poland',
        u: '{1}1617313594942-557bcdff61da{4}{7}U1OXx8cGFsYWNlfGVufDB8fHx8MTYyODU2NTYxNA{5}',
      },
      {
        s: 'palace-of-culture-and-science-from-jana-pankiewicza-in-poland',
        u: '{1}1613166083342-b9a1946c320f{4}{7}M1MTh8fHBhbGFjZXxlbnwwfHx8fDE2Mjg1NjU2MzU{5}',
      },
      {
        s: 'palace-of-culture-and-science-from-złota-in-poland',
        u: '{1}1611414991525-51b8e6409fef{4}{7}E3OHx8d2Fyc2F3fGVufDB8fHx8MTY2MDEwOTEzOA{5}',
      },
      { s: 'gate-at-auschwitz-birkenau-in-poland', u: '{1}1533053832317-687bec0d7d46{2}' },
      {
        s: 'maltijae-pass-from-maltijae-observatory-in-south-korea',
        u: '{1}1590677484545-ca2b592fbbdb{4}{6}E2NTN8fGtvcmVhfGVufDB8fHw{5}',
      },
      { s: 'dolnoslaski-urzad-wojewodzki-in-poland', u: '{1}1528836962038-779fd1943734{2}' },
      {
        s: 'zermatt-from-kirchbrucke-looking-north-in-switzerland',
        u: '{1}1507039102241-5ec61d624406{2}',
      },
      { s: 'zermatt-from-kirchbrucke-in-switzerland', u: '{1}1590254598637-ee4d29f5d9d2{2}' },
      { s: 'lagginhorn-in-switzerland', u: '{1}1589633905509-d07b42c9899f{2}' },
      { s: 'dent-de-valere-in-switzerland', u: '{1}1582621069053-2554b1c5239b{2}' },
      {
        s: 'eglise-rose-from-trient-in-switzerland',
        u: '{1}1541265972967-11869a9f6f23{3}066385552c5330f9af7af7f875c0734f',
      },
      {
        s: 'trinity-college-from-courtyard-in-canada',
        u: '{1}1618255630366-f402c45736f6{4}{7}I3OHx8dW5pdmVyc2l0eXxlbnwwfHx8fDE2NTQwMzI0MjM{5}',
      },
      { s: 'national-monument-in-indonesia', u: '{1}1531453213298-0006c70a1671{2}' },
      { s: 'istiqlal-mosque-in-indonesia', u: '{1}1559753595-fd690ddb3e23{2}' },
      {
        s: 'irian-jaya-liberation-monument-from-lapangan-banteng-park-in-indonesia',
        u: '{1}1578933356686-0592aa9c5363{2}',
      },
      { s: 'jakarta-kota-station-in-indonesia', u: '{1}1568938636799-c745732512ab{2}' },
      {
        s: 'rynek-we-wrocławiu-in-poland',
        u: '{1}1619985726413-812a90b9429f{4}{7}ExMjl8fHBsYXphfGVufDB8fHx8MTYzNTU5NTc5Nw{5}',
      },
      {
        s: 'library-of-celsus-from-behind-in-turkey',
        u: '{1}1623904617206-aa528308e077{4}{7}UzMzV8fHR1cmtleXxlbnwwfHx8fDE2NTU1MzI3OTg{5}',
      },
      {
        s: 'guvercinada-kusadası-in-turkey',
        u: '{1}1645485858906-56fc0972f31f{4}{7}E5Mjl8fHR1cmtleXxlbnwwfHx8fDE2NTU1MzI3MzM{5}',
      },
      {
        s: 'seebensee-from-coburger-hutte-in-austria',
        u: '{1}1529840180348-efd52969a4ce{3}6e51fbe8226a434d8b78f160793b5249',
      },
      {
        s: 'ehrwalder-sonnenspitze-from-lermoos-in-austria',
        u: '{1}1578763748270-d3b2ae8035c1{2}',
      },
      {
        s: 'side-ancient-city-in-turkey',
        u: '{1}1605382628707-0aa0593fba19{4}{7}E1NTl8fHR1cmtleXxlbnwwfHx8fDE2MTcyOTIwMDg{5}',
      },
      {
        s: 'shipyard-alanya-from-tersane-beach-in-turkey',
        u: '{1}1647825531731-c0d1f92e37d3{4}{7}Y4MTJ8fGNhc3RsZXxlbnwwfHx8fDE2NTY5NTQzMDI{5}',
      },
      { s: 'paseo-bandera-in-chile', u: '{1}1580087001803-5221a653aa81{2}' },
      { s: 'cicek-pasajı-in-turkey', u: '{1}1578087543945-3b827394009e{2}' },
      { s: 'the-blue-mosque-from-hagia-sophia-in-turkey', u: '{1}1538135864810-0455c94e3179{2}' },
      { s: 'sokollu-mehmet-pasha-mosque-in-turkey', u: '{1}1585837483130-dc732032a381{2}' },
      {
        s: 'basilica-cistern-in-turkey',
        u: '{1}1621165398199-3eaccda1fc6c{4}{7}Q3NXx8YmFzaWxpY2F8ZW58MHx8fHwxNjU1NzYzODAz{5}',
      },
      {
        s: 'marktkirche-st-bonifacius-from-rathausbrunnen-in-germany',
        u: '{1}1593073814590-521545a47cdb{2}',
      },
    ],
  });
  // return api.spots
  //   .getTopForLanding()
  //   .then(r => r.json())
  //   .then(data => ({ spots: data }));
};

export default {
  getBySlug,
  getByIds,
  getAdditionalData,
  getTopForLanding,
};
