import React from 'react';
import { useNavigate } from 'react-router-dom';
import { urlParserV16 } from '../../utils/spots';
import './styles.scss';

const Photo = ({ spot, onClick }) => {
  return (
    <div className="photo-wrapper" onClick={onClick}>
      <img
        className="photo img"
        src={urlParserV16(spot.u, 400)}
        alt={spot.s.replaceAll('-', ' ')}
        loading="lazy"
      />
    </div>
  );
};

const Slider = ({ spots }) => {
  const navigate = useNavigate();
  const handleClickOnPhoto = ({ s: slug }) => {
    window.gtag('event', 'slider_photo_click', {
      event_label: slug,
      transport_type: 'beacon',
      event_callback: () => {
        navigate(`/spot/${slug}`);
      },
    });
  };

  return (
    <div className="Slider">
      {spots.map(spot => (
        <Photo key={spot.s} spot={spot} onClick={() => handleClickOnPhoto(spot)} />
      ))}
    </div>
  );
};

export default Slider;
