export default {
  'App Store Button': 'App Store按钮',
  'App Store QR Button': 'App Store的QR按钮',
  'Download the app': '下载应用程序',
  featuresQuality:
    '与其他应用程序不同的是，我们所有的照片都经过<1>深层质量控制</1>。不到1％的照片被批准',
  featuresQuantity: 'NoFilter包含全球<1>13000个景点</1>，并且每周大约添加<1>100张新照片</1>',
  featuresPrice: '哦...是的... <1>免费</1>，可用于<1>Android和iOS</1>',
  'Geolocation is not supported by this browser': '此浏览器不支持地理位置',
  'Getting photo locations': '获取照片位置',
  'Getting your location...': '正在获取您的位置...',
  'Google Play Button': 'Google Play按钮',
  'Google Play QR Button': 'Google Play QR按钮',
  'Our goal': '我们的目标',
  'Join NoFilter today': '立即加入NoFilter',
  ourGoal:
    '<1></1>通过提供一个应用程序，使他们可以在世界各地看到酷的照片位置和旅游目的地，来帮助想要拍摄精美照片的 <1>摄影师和旅行者</1>。',
  'Price and Support': '价格与支持',
  Quality: '质量',
  Quantity: '数量',
  'Show me places nearby!': '告诉我附近的地方！',
  'Some examples': '一些例子',
  titleGeneral: '我们帮助<1>旅行者和摄影师</1>发现世界各地<1>最佳拍照地点</1>',
  "Want to see more? Download the app. It's free!": '想看更多？下载应用程序。免费！',
  noLocationPermission:
    '您的浏览器不想告诉我们您的位置:(请从您的PC尝试，或者只是在手机中下载NoFilter并进行实际尝试！',
  'Some cities': '有些城市',
  'Some photos': '一些照片',
  Loading: '正在加载',
  'You just got your free membership!': '您刚刚获得免费会员资格！',
  'Show in Map': '在地图中显示',
};
