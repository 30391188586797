export default {
  'App Store Button': 'Bouton App Store',
  'App Store QR Button': "Bouton QR de l'App Store",
  'Download the app': "Téléchargez l'application",
  featuresQuality:
    "Contrairement à d'autres applications, toutes nos photos sont soumises à un <1>contrôle de qualité approfondi</1>. Moins de 1 % des photos sont approuvées",
  featuresQuantity:
    'NoFilter rassemble <1>13000 endroits</1> à travers le monde, et environ <1>100 nouvelles photos sont ajoutées chaque semaine</1>',
  featuresPrice:
    "Oh... Ouais... Et c'est <1>gratuit</1> , et disponible pour <1>Android et iOS</1>",
  'Geolocation is not supported by this browser':
    "La géolocalisation n'est pas prise en charge par ce navigateur",
  'Getting photo locations': 'Trouver des emplacements pour prendre des photos',
  'Getting your location...': 'Obtention de votre emplacement...',
  'Google Play Button': 'Bouton Google Play',
  'Google Play QR Button': 'Bouton QR de Google Play',
  'Join NoFilter today': "Rejoignez NoFilter dès aujourd'hui",
  'Our goal': 'Notre objectif',
  ourGoal:
    'Aider les <1>photographes et les voyageurs</1> qui veulent réaliser des <1>photos incroyables</1>, en leur fournissant une application qui leur permet de découvrir des endroits sympas où prendre des photos, ainsi que des destinations de voyage, partout dans le monde.',
  'Price and Support': 'Prix et Assistance',
  Quality: 'Qualité',
  Quantity: 'Quantité',
  'Show me places nearby!': 'Montrez-moi les endroits à proximité!',
  'Some examples': 'Quelques exemples',
  titleGeneral:
    'Nous aidons les <1>voyageurs et photographes</1> à découvrir <1>les meilleurs lieux photo</1> partout dans le monde',
  "Want to see more? Download the app. It's free!":
    "Envie d'en voir plus? Téléchargez l'application. C'est gratuit!",
  noLocationPermission:
    'Votre navigateur ne veut pas nous donner votre position :( essayez depuis votre PC, ou téléchargez directement NoFilter sur votre téléphone et essayez-le pour de vrai!',
  'Some cities': 'Quelques villes',
  'Some photos': 'Quelques photos',
  Loading: 'Chargement',
  'You just got your free membership!': "Vous venez d'obtenir votre abonnement gratuit !",
  'Show in Map': 'Afficher sur la carte',
};
