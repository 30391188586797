import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Slider from '../../components/Slider';
import JoinToday from '../../components/JoinToday';
import Download from '../../components/Download';
import { changeCanonical } from 'utils/seo';
import actions from '../../actions';
import './styles.scss';

const randomize = unshuffled =>
  unshuffled
    .map(value => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

const Home = () => {
  const isMobile = window.innerWidth <= 425;

  changeCanonical('/');

  const { t } = useTranslation();
  const [spots, setSpots] = useState([]);

  useEffect(() => {
    (async () => {
      const { spots } = await actions.spots.getTopForLanding();
      setSpots(randomize(spots));
    })();
  }, []);

  /**
   * Determine the mobile operating system.
   * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
   *
   * @returns {String}
   */
  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  };

  const openDownload = () => {
    const device = getMobileOperatingSystem();

    if (device === 'iOS') {
      document.location = 'https://apps.apple.com/app/nofilter-photo-spots/id1445583976';
    } else if (device === 'Android') {
      document.location = 'https://play.google.com/store/apps/details?id=app.no_filter.nofilter';
    }
  };

  return (
    <div className="Home">
      <div className="content-wrapper">
        <div className="hero">
          <div className="main-description">
            <h1>
              <Trans i18nKey="titleGeneral">
                We help <u>travelers and photographers</u> to discover{' '}
                <u>the best photo locations</u> all around the world
              </Trans>
            </h1>
          </div>

          <div className="download-container">
            <Download />
          </div>
        </div>
      </div>

      <div className="sliders">
        <Slider spots={spots.slice(0, isMobile ? 2 * 4 : 4 * 3)} />

        {/* <h2>{t('Some cities')}</h2>
        <div className="cities">
          <a href="/best-photo-spots-in/london-united-kingdom">London, UK</a>
          <a href="/best-photo-spots-in/madrid-spain">Madrid, Spain</a>
          <a href="/best-photo-spots-in/new-york-united-states">New York, United States</a>
          <a href="/best-photo-spots-in/los-angeles-california-united-states">
            Los Angeles, United States
          </a>
        </div> */}
      </div>

      <JoinToday />

      <div className="features">
        <div className="content-wrapper">
          <div className="features-wrapper">
            <div className="features-item get-details">
              <div className="feature-item__icon"></div>

              <h3>{t('Quantity')}</h3>
              <div className="text">
                <Trans i18nKey="featuresQuantity">
                  NoFilter contains <b>13000 spots</b> around the world, and{' '}
                  <b>~100 new photos are added every week</b>
                </Trans>
              </div>
            </div>

            <div className="features-item create-collections">
              <div className="feature-item__icon"></div>

              <h3>{t('Quality')}</h3>
              <div className="text">
                <Trans i18nKey="featuresQuality">
                  Unlike other apps, all our photos go through a <b>deep quality control</b>. Less
                  than 1% of the photos are approved
                </Trans>
              </div>
            </div>

            <div className="features-item share-your-story">
              <div className="feature-item__icon"></div>

              <h3>{t('Price and Support')}</h3>
              <div className="text">
                <Trans i18nKey="featuresPrice">
                  Oh... Yeah... And it's <b>free</b>, and available for <b>Android and iOS</b>
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="social-feature">
        <div className="images" onClick={openDownload}>
          <div className="home"></div>

          <div className="profile"></div>
        </div>

        <div className="content">
          <div className="social-feature-description">
            <h2>{t('Our goal')}</h2>
            <div className="text">
              <Trans i18nKey="ourGoal">
                Help <b>photographers and travelers</b> who want to take <b>amazing pictures</b>, by
                providing an app where they can see cool photo locations, and travel destinations,
                all around the world.
              </Trans>
              <br />
              <br />
              Salud! 🤳
            </div>
          </div>
        </div>
      </div>

      <JoinToday />
    </div>
  );
};

export default Home;
