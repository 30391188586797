import areas from './areas';
import developers from './developers';
import spots from './spots';
import users from './users';

export default {
  areas,
  developers,
  spots,
  users,
};
