import config from './../config';
import fetchUtils from './../utils/fetch';

const getBySlug = slug => {
  return fetch(`${config.api.baseUrl}/landing/v1/areas/get-by-slug/${slug}`, {
    method: 'GET',
    headers: {
      ...fetchUtils.getHeaders(),
    },
  }).then(fetchUtils.parseResponse);
};

export default {
  getBySlug,
};
