import config from './../config';
import fetchUtils from './../utils/fetch';

const get = ({ username }) => {
  const endpoint = 'basic';
  const headers = {
    ...fetchUtils.getHeaders(),
  };

  return fetch(`${config.api.baseUrl}/landing/v1/user/get-by-username/${username}/${endpoint}`, {
    method: 'GET',
    headers,
  }).then(fetchUtils.parseResponse);
};

const unsubscribe = params => {
  return fetch(`${config.api.baseUrl}/app/v1/user/unsubscribe`, {
    method: 'POST',
    headers: {
      ...fetchUtils.getHeaders(),
    },
    body: JSON.stringify(params),
  }).then(fetchUtils.parseResponse);
};

export default {
  get,
  unsubscribe,
};
