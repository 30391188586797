export default {
  'App Store Button': 'Przycisk App Store',
  'App Store QR Button': 'Przycisk QR w App Store',
  'Download the app': 'Pobierz aplikację',
  featuresQuality:
    'W przeciwieństwie do innych aplikacji, wszystkie nasze zdjęcia przechodzą <1>szczegółową kontrolę jakości</1>. Zatwierdzono mniej niż 1% zdjęć',
  featuresQuantity:
    'NoFilter zawiera <1>13000 miejsc</1> na całym świecie, a około <1>co tydzień dodaje się 100 nowych zdjęć</1>',
  featuresPrice: 'Och... Tak... I jest <1>bezpłatny</1> i dostępny dla <1>Androida i iOS</1>',
  'Geolocation is not supported by this browser':
    'Geolokalizacja nie jest obsługiwana przez tę przeglądarkę',
  'Getting photo locations': 'Pobieranie lokalizacji zdjęć',
  'Getting your location...': 'Uzyskiwanie Twojej lokalizacji...',
  'Google Play Button': 'Przycisk Google Play',
  'Google Play QR Button': 'Przycisk Google Play QR',
  'Join NoFilter today': 'Dołącz do NoFilter już dziś',
  'Our goal': 'Nasz cel',
  ourGoal:
    'Pomóż <1>fotografom i podróżnikom</1>, którzy chcą robić <1>niesamowite zdjęcia</1>, udostępniając aplikację, w której mogą zobaczyć fajne lokalizacje zdjęć i miejsca podróży na całym świecie.',
  'Price and Support': 'Cena i wsparcie',
  Quality: 'Jakość',
  Quantity: 'Ilość',
  'Show me places nearby!': 'Pokaż mi miejsca w pobliżu!',
  'Some examples': 'Kilka przykładów',
  titleGeneral:
    'Pomagamy <1>podróżnym i fotografom</1> odkryć <1>najlepsze lokalizacje zdjęć</1> na całym świecie',
  "Want to see more? Download the app. It's free!":
    'Chcę zobaczyć więcej? Pobierz aplikację. Jest wolne!',
  noLocationPermission:
    'Twoja przeglądarka nie chce podać nam Twojej pozycji :( spróbuj z komputera lub po prostu pobierz NoFilter na swój telefon i wypróbuj go naprawdę!',
  'Some cities': 'Niektóre miasta',
  'Some photos': 'Jakieś zdjęcia',
  Loading: 'Ładowanie',
  'You just got your free membership!': 'Właśnie otrzymałeś darmowe członkostwo!',
  'Show in Map': 'Pokaż na mapie',
};
