export default {
  'App Store Button': 'Tombol App Store',
  'App Store QR Button': 'Tombol QR App Store',
  'Download the app': 'Unduh aplikasi',
  featuresQuality:
    'Tidak seperti aplikasi lain, semua foto kami melalui <1>kontrol kualitas yang mendalam</1>. Kurang dari 1% foto disetujui',
  featuresQuantity:
    'NoFilter berisi <1>13000 tempat</1> di seluruh dunia, dan sekitar <1>100 foto baru ditambahkan setiap minggu</1>',
  featuresPrice: 'Oh... Ya... Dan itu <1>gratis</1>, dan tersedia untuk <1>Android dan iOS</1>',
  'Geolocation is not supported by this browser': 'Geolokasi tidak didukung oleh browser ini',
  'Getting photo locations': 'Mendapatkan lokasi foto',
  'Getting your location...': 'Mendapatkan lokasi Anda...',
  'Google Play Button': 'Tombol Google Play',
  'Google Play QR Button': 'Tombol Google Play QR',
  'Join NoFilter today': 'Bergabunglah dengan NoFilter hari ini',
  'Our goal': 'Tujuan kita',
  ourGoal:
    'Bantu <1>fotografer dan pelancong</1> yang ingin mengambil <1>gambar menakjubkan</1>, dengan menyediakan aplikasi tempat mereka dapat melihat lokasi foto keren, dan tujuan perjalanan, di seluruh dunia.',
  'Price and Support': 'Harga dan Dukungan',
  Quality: 'Kualitas',
  Quantity: 'Kuantitas',
  'Show me places nearby!': 'Tunjukkan tempat terdekat!',
  'Some examples': 'Beberapa contoh',
  titleGeneral:
    'Kami membantu <1>wisatawan dan fotografer</1> menemukan <1>lokasi foto terbaik</1> di seluruh dunia',
  "Want to see more? Download the app. It's free!":
    'Ingin melihat lebih banyak? Unduh aplikasinya. Gratis!',
  noLocationPermission:
    'Peramban Anda tidak ingin memberi kami posisi Anda :( coba dari PC Anda, atau cukup unduh NoFilter di telepon Anda dan cobalah secara nyata!',
  'Some cities': 'Beberapa kota',
  'Some photos': 'Beberapa foto',
  Loading: 'Memuat',
  'You just got your free membership!': 'Anda baru saja mendapatkan keanggotaan gratis!',
  'Show in Map': 'Tampilkan di Peta',
};
