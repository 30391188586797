export default {
  'App Store Button': 'Botón de la Tienda de Apple',
  'App Store QR Button': 'Botón QR de la Tienda de Apple',
  'Download the app': 'Descarga la app',
  featuresQuality:
    'A diferencia de otras aplicaciones, todas nuestras fotos pasan por un <1>estricto control de calidad</1>. Menos del 1% de las fotos son aprobadas',
  featuresQuantity:
    'NoFilter contiene <1>13000 lugares</1> en todo el mundo, y se agregan alrededor de <1>100 puntos nuevos cada semana</1>',
  featuresPrice: 'Oh, si... Y es <1>gratis</1>, y está disponible para <1>Android y iOS</1>',
  'Geolocation is not supported by this browser':
    'La geolocalización no es compatible con este navegador',
  'Getting photo locations': 'Obteniendo ubicaciones',
  'Getting your location...': 'Obteniendo tu ubicación...',
  'Google Play Button': 'Botón de Google Play',
  'Google Play QR Button': 'Botón QR de Google Play',
  'Join NoFilter today': 'Únete a NoFilter hoy',
  'Our goal': 'Nuestro objetivo',
  ourGoal:
    'Ayudar a <1>fotógrafos y viajeros</1> que quieran tomar <1>fotos increíbles</1>, proporcionando una app donde puedan ver ubicaciones geniales para fotografiar, y ver excelentes destinos de viajes, en todo el mundo.',
  'Price and Support': 'Precio y Soporte',
  Quality: 'Calidad',
  Quantity: 'Cantidad',
  'Show me places nearby!': '¡Muéstrame lugares cercanos!',
  'Some examples': 'Algunos ejemplos',
  titleGeneral:
    'Ayudamos a <1>viajeros y fotografos</1> a descubrir <1>los mejores rincones del mundo</1> para tomar fotografías',
  "Want to see more? Download the app. It's free!":
    '¿Quieres ver más? Descargue la app. ¡Es gratis!',
  noLocationPermission:
    'Su navegador no quiere darnos su posición :( intente desde su PC, o simplemente descargue NoFilter en su teléfono y pruébelo de verdad.',
  'Some cities': 'Algunas ciudades',
  'Some photos': 'Algunas fotos',
  Loading: 'Cargando',
  'You just got your free membership!': '¡Acabas de obtener tu membresía gratis!',
  'Show in Map': 'Mostrar en mapa',
};
