export default {
  'App Store Button': 'Hnappur App Store',
  'App Store QR Button': 'App Store QR hnappur',
  'Download the app': 'Sækja appið',
  featuresQuality:
    'Ólíkt öðrum forritum fara allar myndir okkar í gegnum <1>djúpt gæðaeftirlit</1>. Minna en 1% myndanna eru samþykktar',
  featuresQuantity:
    'NoFilter inniheldur <1>13000 blettir</1> um allan heim og um það bil <1>100 nýjar myndir eru bætt við í hverri viku</1>',
  featuresPrice: 'Ó... Já... Og það er <1>ókeypis</1>, og til í <1>Android og iOS</1>',
  'Geolocation is not supported by this browser':
    'Landfræðileg staðsetning er ekki studd af þessum vafra',
  'Getting photo locations': 'Að fá ljósmyndastaði',
  'Getting your location...': 'Sækir staðsetningu þína...',
  'Google Play Button': 'Google Play hnappur',
  'Google Play QR Button': 'Google Play QR hnappur',
  'Join NoFilter today': 'Vertu með í NoFilter í dag',
  'Our goal': 'Markmið okkar',
  ourGoal:
    'Hjálpaðu <1>ljósmyndurum og ferðamönnum</1> sem vilja taka <1>ótrúlegar myndir</1>, með því að bjóða upp á app þar sem þeir geta séð flottar ljósmyndastöður og áfangastaði um allan heim.',
  'Price and Support': 'Verð og stuðningur',
  Quality: 'Gæði',
  Quantity: 'Magn',
  'Show me places nearby!': 'Sýndu mér staði í nágrenninu!',
  'Some examples': 'Nokkur dæmi',
  titleGeneral:
    'Við hjálpum <1>ferðamönnum og ljósmyndurum</1> að uppgötva <1>bestu ljósmyndastaðina</1> um allan heim',
  "Want to see more? Download the app. It's free!": 'Viltu sjá meira? Sæktu appið. Það er ókeypis!',
  noLocationPermission:
    'Vafrinn þinn vill ekki gefa okkur stöðu þína :( prófaðu úr tölvunni þinni, eða sæktu bara NoFilter í símann þinn og prófaðu það fyrir alvöru!',
  'Some cities': 'Sumar borgir',
  'Some photos': 'Nokkrar myndir',
  Loading: 'Hleðsla',
  'You just got your free membership!': 'Þú fékkst bara ókeypis aðild þína!',
  'Show in Map': 'Sýna á korti',
};
