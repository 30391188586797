export default {
  'App Store Button': 'App Store-Taste',
  'App Store QR Button': 'App Store-QR-Taste',
  'Download the app': 'Laden Sie die Anwendung herunter',
  featuresQuality:
    'Im Gegensatz zu anderen Apps durchlaufen alle unsere Fotos eine <1>tiefgehende Qualitätskontrolle</1>. Weniger als 1 % der Fotos wird genehmigt',
  featuresQuantity:
    'NoFilter enthält <1>13000 Spots</1> auf der ganzen Welt, und etwa <1>100 neue Fotos kommen jede Woche hinzu</1>',
  featuresPrice:
    'Oh... Ja... Und es ist <1>kostenlos</1>, und für <1>Android und iOS</1> verfügbar',
  'Geolocation is not supported by this browser':
    'Geolocation wird von diesem Browser nicht unterstützt',
  'Getting photo locations': 'Foto-Standorte abrufen',
  'Getting your location...': 'Abruf deines Standorts...',
  'Google Play Button': 'Google Play-Taste',
  'Google Play QR Button': 'Google Play-QR-Taste',
  'Join NoFilter today': 'Noch heute bei NoFilter anmelden',
  'Our goal': 'Unser Ziel',
  ourGoal:
    '<1>Fotografen und Reisenden</1> helfen, die <1>tolle Fotos</1> machen wollen, indem wir eine App bereitstellen, in der sie coole Foto-Standorte und Reiseziele weltweit sehen können.',
  'Price and Support': 'Preis und Support',
  Quality: 'Qualität',
  Quantity: 'Menge',
  'Show me places nearby!': 'Zeige mir Orte in der Nähe!',
  'Some examples': 'Ein paar Beispiele',
  titleGeneral:
    'Wir helfen <1>Reisenden und Fotografen</1> dabei, <1>die besten Fotolocations</1> auf der ganzen Welt zu entdecken',
  "Want to see more? Download the app. It's free!":
    'Möchtest du mehr sehen? Lade die App herunter. Es ist kostenlos!',
  noLocationPermission:
    'Dein Browser will uns deine Position nicht mitteilen :( Versuche es von deinem PC aus, oder lade NoFilter einfach auf dein Handy herunter und probiere es in echt!',
  'Some cities': 'Einige Städte',
  'Some photos': 'Manche Fotos',
  Loading: 'Wird geladen',
  'You just got your free membership!': 'Sie haben gerade Ihre kostenlose Mitgliedschaft erhalten!',
  'Show in Map': 'In Karte anzeigen',
};
