export default {
  'App Store Button': 'App Store Button',
  'App Store QR Button': 'App Store QR Button',
  'Download the app': 'Download the app',
  featuresQuality:
    'Unlike other apps, all our photos go through a <1>deep quality control</1>. Less than 1% of the photos are approved',
  featuresQuantity:
    'NoFilter contains <1>13000 spots</1> around the world, and around <1>100 new photos are added every week</1>',
  featuresPrice: "Oh... Yeah... And it's <1>free</1>, and available for <1>Android and iOS</1>",
  'Geolocation is not supported by this browser': 'Geolocation is not supported by this browser',
  'Getting photo locations': 'Getting photo locations',
  'Getting your location...': 'Getting your location...',
  'Google Play Button': 'Google Play Button',
  'Google Play QR Button': 'Google Play QR Button',
  'Join NoFilter today': 'Join NoFilter today',
  'Our goal': 'Our goal',
  ourGoal:
    'Help <1>photographers and travelers</1> who want to take <1>amazing pictures</1>, by providing an app where they can see cool photo locations, and travel destinations, all around the world.',
  'Price and Support': 'Price and Support',
  Quality: 'Quality',
  Quantity: 'Quantity',
  'Show me places nearby!': 'Show me places nearby!',
  'Some examples': 'Some examples',
  titleGeneral:
    'We help <1>travelers and photographers</1> to discover <1>the best photo locations</1> all around the world',
  "Want to see more? Download the app. It's free!":
    "Want to see more? Download the app. It's free!",
  noLocationPermission:
    'Your browser do not give us your location :( try again from your PC, or just download the app and try it for real!',
  'Some cities': 'Some cities',
  'Some photos': 'Some photos',
  Loading: 'Loading',
  'You just got your free membership!': 'You just got your free membership!',
  'Show in Map': 'Show in Map',
};
