export default {
  'App Store Button': 'Pulsante App Store',
  'App Store QR Button': "Pulsante QR dell'App Store",
  'Download the app': "Scarica l'applicazione",
  featuresQuality:
    "A differenza di altre app, tutte le nostre foto vengono sottoposte a un <1>controllo qualità approfondito</1>. Meno dell'1% delle foto viene approvato",
  featuresQuantity:
    'NoFilter contiene <1>13000 angoli</1> in tutto il mondo, e ogni settimana vengono aggiunte circa <1>100 nuove foto</1>',
  featuresPrice: 'Oh... Sì... Ed è <1>gratuito</1>, disponibile per <1>Android e iOS</1>',
  'Geolocation is not supported by this browser':
    'La geolocalizzazione non è supportata da questo browser',
  'Getting photo locations': 'Ottenere le posizioni delle foto',
  'Getting your location...': 'Recupero della tua posizione...',
  'Google Play Button': 'Pulsante Google Play',
  'Google Play QR Button': 'Pulsante QR di Google Play',
  'Join NoFilter today': 'Iscriviti a NoFilter oggi',
  'Our goal': 'Il nostro obbiettivo',
  ourGoal:
    "Aiutare <1>fotografi e viaggiatori</1> che vogliano scattare <1>foto straordinarie</1>, fornendo un'app in cui possano vedere fantastiche località fotografiche e destinazioni di viaggio, in tutto il mondo.",
  'Price and Support': 'Prezzo e Supporto',
  Quality: 'Qualità',
  Quantity: 'Quantità',
  'Show me places nearby!': 'Mostrami i luoghi nelle vicinanze!',
  'Some examples': 'Qualche esempio',
  titleGeneral:
    'Aiutiamo <1>viaggiatori e fotografi</1> a scoprire <1>i migliori luoghi fotografici</1> in tutto il mondo',
  "Want to see more? Download the app. It's free!": "Vuoi vedere di più? Scarica l'app. È gratis!",
  noLocationPermission:
    'Il tuo browser non vuole darci la tua posizione :( prova dal tuo PC, o scarica semplicemente NoFilter sul tuo telefono e provalo per davvero!',
  'Some cities': 'Alcune città',
  'Some photos': 'Qualche foto',
  Loading: 'Caricamento in corso',
  'You just got your free membership!': 'Hai appena ricevuto la tua iscrizione gratuita!',
  'Show in Map': 'Mostra nella mappa',
};
