export default {
  'App Store Button': 'App Store Düğmesi',
  'App Store QR Button': 'App Store QR Düğmesi',
  'Download the app': 'Uygulamayı indir',
  featuresQuality:
    "Diğer uygulamalardan farklı olarak, tüm fotoğraflarımız <1>derin kalite kontrolünden</1> geçer. Fotoğrafların% 1'inden azı onaylandı",
  featuresQuantity:
    'NoFilter dünyada <1>13000 nokta</1> içeriyor ve her hafta yaklaşık <1>100 yeni fotoğraf ekleniyor</1>',
  featuresPrice: 'Oh... Evet... Ve <1>ücretsiz</1> ve <1>Android ve iOS</1> için kullanılabilir',
  'Geolocation is not supported by this browser':
    'Coğrafi konum bu tarayıcı tarafından desteklenmiyor',
  'Getting photo locations': 'Fotoğraf konumlarını alma',
  'Getting your location...': 'Konumunuz alınıyor...',
  'Google Play Button': 'Google Play Düğmesi',
  'Google Play QR Button': 'Google Play QR Düğmesi',
  'Join NoFilter today': "Bugün NoFilter'a katılın",
  'Our goal': 'Hedefimiz',
  ourGoal:
    'Dünyanın dört bir yanındaki havalı fotoğraf konumlarını ve seyahat yerlerini görebilecekleri bir uygulama sunarak <1></1>muhteşem fotoğraflar çekmek isteyen <1>fotoğrafçılarına ve gezginlerine</1> yardım edin.',
  'Price and Support': 'Fiyat ve Destek',
  Quality: 'Kalite',
  Quantity: 'miktar',
  'Show me places nearby!': 'Bana yakındaki yerleri göster!',
  'Some examples': 'Bazı örnekler',
  titleGeneral:
    '<1>Gezginlerin ve fotoğrafçıların</1> tüm dünyadaki <1>en iyi fotoğraf konumlarını</1> keşfetmelerine yardımcı oluyoruz',
  "Want to see more? Download the app. It's free!":
    'Daha fazlasını görmek ister misiniz? Uygulamayı indirin. Bedava!',
  noLocationPermission:
    "Tarayıcınız bize konumunuzu vermek istemiyor :( PC'nizden deneyin veya sadece NoFilter'ı telefonunuza indirin ve gerçek için deneyin!",
  'Some cities': 'Bazı şehirler',
  'Some photos': 'Bazı fotoğraflar',
  Loading: 'Yükleniyor',
  'You just got your free membership!': 'Ücretsiz üyeliğinizi yeni aldınız!',
  'Show in Map': 'Haritada Göster',
};
