export default {
  'App Store Button': 'App Store-knop',
  'App Store QR Button': 'App Store QR-knop',
  'Download the app': 'Download de app',
  featuresQuality:
    "In tegenstelling tot andere apps ondergaan al onze foto's een <1>diepe kwaliteitscontrole</1>. Minder dan 1% van de foto's is goedgekeurd",
  featuresQuantity:
    "NoFilter bevat <1>13000 spots</1> over de hele wereld en er worden wekelijks ongeveer <1>100 nieuwe foto's toegevoegd</1>",
  featuresPrice: 'Oh... Ja... En het is <1>gratis</1> en beschikbaar voor <1>Android en iOS</1>',
  'Geolocation is not supported by this browser':
    'Geolocatie wordt niet ondersteund door deze browser',
  'Getting photo locations': 'Fotolocaties ophalen',
  'Getting your location...': 'Uw locatie ophalen...',
  'Google Play Button': 'Google Play-knop',
  'Google Play QR Button': 'Google Play QR-knop',
  'Join NoFilter today': 'Word vandaag lid van NoFilter',
  'Our goal': 'Ons doel',
  ourGoal:
    "Help <1>fotografen en reizigers</1> die <1>geweldige foto's</1> willen maken door een app te bieden waarmee ze coole fotolocaties en reisbestemmingen over de hele wereld kunnen zien.",
  'Price and Support': 'Prijs en ondersteuning',
  Quality: 'Kwaliteit',
  Quantity: 'Aantal stuks',
  'Show me places nearby!': 'Laat me plaatsen in de buurt zien!',
  'Some examples': 'Een paar voorbeelden',
  titleGeneral:
    'We helpen <1>reizigers en fotografen</1> om <1>de beste fotolocaties</1> over de hele wereld te ontdekken',
  "Want to see more? Download the app. It's free!":
    'Wil meer zien? Download de app. Het is gratis!',
  noLocationPermission:
    'Uw browser wil ons uw standpunt niet geven :( probeer het vanaf uw pc of download gewoon NoFilter op uw telefoon en probeer het echt!',
  'Some cities': 'Sommige steden',
  'Some photos': "Een paar foto's",
  Loading: 'Bezig met laden',
  'You just got your free membership!': 'Je hebt zojuist je gratis lidmaatschap gekregen!',
  'Show in Map': 'Toon in kaart',
};
