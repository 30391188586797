export default {
  'App Store Button': 'Butang App Store',
  'App Store QR Button': 'Butang QR App Store',
  'Download the app': 'Muat turun aplikasi',
  featuresQuality:
    'Tidak seperti aplikasi lain, semua foto kami melalui <1>kawalan kualiti mendalam</1>. Kurang daripada 1% foto diluluskan',
  featuresQuantity:
    'NoFilter berisi <1>13000 tempat</1> di seluruh dunia, dan sekitar <1>100 foto baru ditambahkan setiap minggu</1>',
  featuresPrice: 'Oh... Ya... Dan itu <1>percuma</1>, dan tersedia untuk <1>Android dan iOS</1>',
  'Geolocation is not supported by this browser':
    'Geolokasi tidak disokong oleh penyemak imbas ini',
  'Getting photo locations': 'Mendapatkan lokasi gambar',
  'Getting your location...': 'Mendapatkan lokasi anda...',
  'Google Play Button': 'Butang Google Play',
  'Google Play QR Button': 'Butang QR Google Play',
  'Join NoFilter today': 'Sertailah NoFilter hari ini',
  'Our goal': 'Matlamat kami',
  ourGoal:
    'Bantu <1>jurugambar dan pelancong</1> yang ingin mengambil <1>gambar luar biasa</1>, dengan menyediakan aplikasi di mana mereka dapat melihat lokasi foto yang keren, dan destinasi pelancongan, di seluruh dunia.',
  'Price and Support': 'Harga dan Sokongan',
  Quality: 'Kualiti',
  Quantity: 'Kuantiti',
  'Show me places nearby!': 'Tunjukkan tempat berdekatan!',
  'Some examples': 'Beberapa contoh',
  titleGeneral:
    'Kami membantu <1>pengembara dan jurugambar</1> untuk menemui <1>lokasi foto terbaik</1> di seluruh dunia',
  "Want to see more? Download the app. It's free!":
    'Ingin melihat lebih banyak? Muat turun aplikasi. Ianya percuma!',
  noLocationPermission:
    'Penyemak imbas anda tidak mahu memberi kami kedudukan anda :( cuba dari PC anda, atau hanya memuat turun NoFilter di telefon anda dan mencubanya secara sebenar!',
  'Some cities': 'Beberapa bandar',
  'Some photos': 'Beberapa gambar',
  Loading: 'Memuatkan',
  'You just got your free membership!': 'Anda baru sahaja mendapat keahlian percuma anda!',
  'Show in Map': 'Tunjukkan dalam Peta',
};
