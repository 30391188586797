export default {
  'App Store Button': 'App Store按鈕',
  'App Store QR Button': 'App Store的QR按鈕',
  'Download the app': '下載應用程序',
  featuresQuality:
    '與其他應用程序不同的是，我們所有的照片都經過<1>深層質量控制</1>。不到1％的照片被批准',
  featuresQuantity: 'NoFilter包含全球<1>13000個景點</1>，並且每周大約添加<1>100張新照片</1>',
  featuresPrice: '哦...是的... <1>免費</1>，可用於<1>Android和iOS</1>',
  'Geolocation is not supported by this browser': '此瀏覽器不支持地理位置',
  'Getting photo locations': '獲取照片位置',
  'Getting your location...': '正在獲取您的位置...',
  'Google Play Button': 'Google Play按鈕',
  'Google Play QR Button': 'Google Play QR按鈕',
  'Join NoFilter today': '立即加入NoFilter',
  'Our goal': '我們的目標',
  ourGoal:
    '<1></1>通過提供一個可以查看全世界很酷的照片位置和旅遊目的地的應用程序，幫助想要令人驚奇的照片<1>的攝影師和旅行者</1>。',
  'Price and Support': '價格與支持',
  Quality: '質量',
  Quantity: '數量',
  'Show me places nearby!': '告訴我附近的地方！',
  'Some examples': '一些例子',
  titleGeneral: '我們幫助<1>旅行者和攝影師</1>發現世界各地<1>最佳拍照地點</1>',
  "Want to see more? Download the app. It's free!": '想看更多？下載應用程序。免費！',
  noLocationPermission:
    '您的瀏覽器不想告訴我們您的位置:(請從您的PC嘗試，或者只是在手機中下載NoFilter並進行實際嘗試！',
  'Some cities': '有些城市',
  'Some photos': '一些照片',
  Loading: '正在加載',
  'You just got your free membership!': '您剛剛獲得免費會員資格！',
  'Show in Map': '在地圖中顯示',
};
