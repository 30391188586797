import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import c from 'classnames';
import JoinToday from 'components/JoinToday';
import Download from 'components/Download';
import Loading from 'components/Loading';
import actions from 'actions';
import { changeCanonical } from 'utils/seo';
import './styles.scss';

const TABS = {
  MY_SPOTS: 'My Spots',
  MY_COLLECTIONS: 'Collections',
  MY_VISITED_SPOTS: 'Visited',
};

const PublicProfile = () => {
  const params = useParams();
  const navigate = useNavigate();
  const username = params.username.toLowerCase();
  const [user, setUser] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(TABS.MY_SPOTS);

  changeCanonical(`/${username}`, {
    description: `Check the spots uploaded by ${username} in NoFilter App. More information about these spots in NoFilter App`,
  });

  useEffect(() => {
    try {
      window.location = `nofilter://user?username=${username}`;
    } catch (e) {}

    (async () => {
      try {
        const { user } = await actions.users.get({ username });

        if (user) {
          setUser(user);
        }
      } catch (error) {
        //
      }

      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = photo => {
    if (photo.assignedSpot && photo.assignedSpot.slug) {
      navigate(`/spot/${photo.assignedSpot.slug}`);
    }
  };

  if (isLoading) {
    return (
      <div className="PublicProfile">
        <div className="content-wrapper">
          <Loading />
        </div>
      </div>
    );
  }

  if (!user) {
    return (
      <div className="PublicProfile">
        <div className="content-wrapper">404: @{user.username} user not found</div>
      </div>
    );
  }

  return (
    <div className="PublicProfile">
      <div className="content-wrapper">
        <div className="PublicProfile__header">
          <div
            className="PublicProfile__avatar"
            style={{ backgroundImage: `url(${user.avatarUrl})` }}
          >
            {!user.avatarUrl && user.username[0].toUpperCase()}
          </div>

          <div className="PublicProfile__data">
            <div className="PublicProfile__username">@{user.username}</div>
            <div className="PublicProfile__followers">
              {user.followersCount} followers - {user.followingCount} following
            </div>
          </div>
        </div>

        <Download />

        <div className="PublicProfile__tabs">
          <div
            className={c('PublicProfile__tab', { active: currentTab === TABS.MY_SPOTS })}
            onClick={() => setCurrentTab(TABS.MY_SPOTS)}
          >
            {TABS.MY_SPOTS}
          </div>

          <div
            className={c('PublicProfile__tab', { active: currentTab === TABS.MY_COLLECTIONS })}
            onClick={() => setCurrentTab(TABS.MY_COLLECTIONS)}
          >
            {TABS.MY_COLLECTIONS}
          </div>

          <div
            className={c('PublicProfile__tab', { active: currentTab === TABS.MY_VISITED_SPOTS })}
            onClick={() => setCurrentTab(TABS.MY_VISITED_SPOTS)}
          >
            {TABS.MY_VISITED_SPOTS}
          </div>
        </div>

        <div className="PublicProfile__content">
          {currentTab !== TABS.MY_SPOTS && <></>}
          {currentTab === TABS.MY_SPOTS && (
            <div className="PublicProfile__photos">
              {user.photos.length > 0 &&
                user.photos.map(x => (
                  <div
                    key={x.file}
                    className="PublicProfile__photo"
                    onClick={() => handleClick(x)}
                    style={{
                      backgroundImage: `url(https://storage.googleapis.com/mari-a5cc7.appspot.com/photos/regular/${x.file})`,
                    }}
                  ></div>
                ))}
            </div>
          )}
        </div>
      </div>

      <JoinToday />
    </div>
  );
};

export default PublicProfile;
