export default {
  'App Store Button': 'App Storeボタン',
  'App Store QR Button': 'App Store QRボタン',
  'Download the app': 'アプリをダウンロード',
  featuresQuality:
    '他のアプリとは異なり、すべての写真は<1>詳細な品質管理</1>を経ています。写真の1％未満が承認されました',
  featuresQuantity:
    'NoFilterには世界中に<1>13000スポット</1>が含まれており、<1>毎週約100枚の新しい写真が追加されます</1>',
  featuresPrice: 'ああ...ええ...そして<1>無料</1>で、<1>AndroidとiOS</1>で利用可能です',
  'Geolocation is not supported by this browser': 'このブラウザは位置情報をサポートしていません',
  'Getting photo locations': '写真の場所を取得しています',
  'Getting your location...': '現在地を取得しています...',
  'Google Play Button': 'Google Playボタン',
  'Google Play QR Button': 'Google Play QRボタン',
  'Join NoFilter today': 'NoFilterに今すぐ参加',
  'Our goal': '私たちの目的',
  ourGoal:
    '世界中の素敵な写真の場所や旅行先を確認できるアプリを提供することで、<1>素晴らしい写真</1>を撮りたい<1>写真家や旅行者</1>を助けましょう。',
  'Price and Support': '価格とサポート',
  Quality: '品質',
  Quantity: '量',
  'Show me places nearby!': '近くの場所を見せて！',
  'Some examples': 'いくつかの例',
  titleGeneral: '<1>旅行者や写真家</1>が世界中の<1>最高の写真の場所</1>を見つけるお手伝いをします',
  "Want to see more? Download the app. It's free!":
    'もっと見たいです？アプリをダウンロードしてください。それは無料です！',
  noLocationPermission:
    'あなたのブラウザは私たちにあなたの位置を与えたくありません:(あなたのPCから試すか、あなたの電話にNoFilterをダウンロードして実際に試してください！',
  'Some cities': 'いくつかの都市',
  'Some photos': 'いくつかの写真',
  Loading: '読み込み中',
  'You just got your free membership!': '無料メンバーシップを取得しました。',
  'Show in Map': '地図で表示',
};
